import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import moment from 'moment';

Vue.config.productionTip = false


//IDIOMAS
import VueI18n from 'vue-i18n'
import messages from './translations';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.state.language, 
  messages,
});
//.IDIOMAS

/*AXIOS*/
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.baseURL = store.state.server;
Vue.use(VueAxios, axios)
let token = window.localStorage.getItem('_token');
if (token) {
  axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
}
/*AXIOS*/

/*Validator Base*/
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import { extend } from 'vee-validate';
import { email, required, min, confirmed, numeric, max, max_value } from 'vee-validate/dist/rules';
extend('email', {
  ...email,
  message: 'El email está mal formado'
});

extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
});

extend('numeric', {
  ...numeric,
  message: 'Este campo debe contener números'
});

extend('confirmed', {
  ...confirmed,
  message: 'Las contraseñas no son iguales'
});

extend('min', {
  ...min,
  message: 'Este campo no tiene la longitud mínima'
});

extend('max', {
  ...max,
  message: 'Este campo sobrepasa la longitud máxima'
});


extend('max_value', {
  ...max_value,
  message: 'El número máximo es 2'
});

extend('validdni', dni => {

  let numero, firstlet, letra;
  let expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

  dni = dni.toUpperCase();

  if (expresion_regular_dni.test(dni) === true) {
    numero = dni.substr(0, dni.length - 1);
    numero = numero.replace('X', 0);
    numero = numero.replace('Y', 1);
    numero = numero.replace('Z', 2);
    firstlet = dni.substr(dni.length - 1, 1);
    numero = numero % 23;
    letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letra = letra.substring(numero, numero + 1);
    if (letra != firstlet) {
      return "La letra del DNI es erronea";
    } else {
      return true;
    }
  } else {
    return "DNI erroneo, formato no válido";
  }

});


import VCalendar from 'v-calendar';
Vue.use(VCalendar);


import datepicker from '@/components/datepicker';
Vue.component('datepicker', datepicker);

import yeardatepicker from '@/components/year-datepicker';
Vue.component('year-datepicker', yeardatepicker);

import popupconfirmacion from '@/components/popup-confirmacion';
Vue.component('popup-confirmacion', popupconfirmacion);

import editorjs from '@/components/editorjs';
Vue.component('editorjs', editorjs);

import rangeselector from '@/components/range-selector';
Vue.component('range-selector', rangeselector);

import alert from '@/components/alert';
Vue.component('alert', alert);

import autocomplete from '@/components/autocomplete'
Vue.component('autocomplete', autocomplete)

Vue.filter('date', function (value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  } else {
    return '----'
  }
});


extend('validimage', value => {

  if (value.length == 1) {
    let file = value[0];
    if (file.size > 2000000) {
      return 'El tamaño del fichero no puede ser superior a 2MB';
    } else if (!file.type.includes('image')) {
      return 'El fichero debe ser una imagen';
    } else {
      return true;
    }

  } else {

    let error = {
      pos: '',
      error: ''
    };

    value.forEach((e, key) => {

      if (e.size > 2000000) {
        error.pos = key;
        error.error = 'size';
      } else if (!e.type.includes('image')) {
        error.pos = key;
        error.error = 'type';
      } else {
        error.pos = '-1';
        error.error = true;
      }
    });

    if (error.error == true) {
      return true;
    } else if (error.error == "size") {
      return `La imagen número ${error.pos + 1} tiene un tamaño superior a 2MB`;
    } else if (error.error == 'type') {
      return `El fichero número ${error.pos + 1} no es una imagen`;
    }

  }

});



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
